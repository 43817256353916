import React, {useEffect, useState} from 'react';
import {IUserDetails} from '@vivli/features/users/infrastructure/interface';

import {GridPageComponent} from '@vivli/shared/components';
import {DataTransformationService} from '@vivli/shared/infrastructure/service';
import {GridCellRendererEnum} from '@vivli/shared/infrastructure/enum';
import {ColDef} from 'ag-grid-community';
import {useOrganizations} from "@vivli/features/organizations/infrastructure/hook";
import {useUsers} from "@vivli/features/users/infrastructure/hook";
import {useNavigate} from "react-router-dom";

const columnDefs: ColDef[] = [
    {
        field: 'email',
        headerName: 'Email Address',
        filter: true,
        sortable: true
    },
    {
        field: 'displayNameWithAdminStatus',
        headerName: 'Name',
        filter: true,
        sortable: true,
        initialSort: 'asc'
    },
    {
        field: 'activeOrgs',
        headerName: 'Organizations',
        filter: true,
        sortable: true,
        cellRenderer: GridCellRendererEnum.GridCellList,
        cellRendererParams: {emptyMessage: 'No Organization'}
    },
    {
        field: 'status',
        headerName: 'Account Status',
        filter: true,
        sortable: true
    },
    {
        field: 'daysSinceLastLogin',
        headerName: 'Days Since Last Login',
        filter: true,
        sortable: true
    }]

export const UsersFeature = () => {
    const [formattedUsers, setFormattedUsers] = useState<IUserDetails[]>(null);
    const {formatUserDetails} = DataTransformationService;
    const {organizations} = useOrganizations();
    const {users, isLoadingUsers} = useUsers();
    const navigate = useNavigate();

    const formatUser = (user: IUserDetails) => {
        const orgs = user.userRole.orgMemberships.map(role => {
            return organizations.find(o => o.id === role.orgId)?.name || null;
        }).filter(org => org);


        const activeOrgs = user.userRole.orgMemberships.map(role => {
            return organizations.find(o => o.id === role.orgId && role.isEnabled
                == true)?.name || null;
        }).filter(org => org);


        return {
            ...formatUserDetails(user),
            orgs,
            activeOrgs
        }
    }


    const sortUsers = (a, b) => a.displayName.localeCompare(b.displayName);

    const formatUsers = (users: IUserDetails[]) => {
        const formattedUsers = users.map(formatUser).sort(sortUsers);
        setFormattedUsers(formattedUsers);
    }

    useEffect(() => {
        if (!users || !organizations) {
            return;
        }

        formatUsers(users);
    }, [users, organizations])
    const handleRowClick = (data) => {
        navigate(`/admin/users/${data.id}`, {state: data});
    }


    return (
        <GridPageComponent
            title='User Management'
            columnDefs={columnDefs}
            rowData={formattedUsers}
            onRowClick={handleRowClick}
            exportTitle='Users'
            fullWidthColumns={true}
            asyncUpdateKey='id'
            dataRefreshing={isLoadingUsers}
        />
    )
}
