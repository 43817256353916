import React, {CSSProperties, useRef, useState} from 'react';
import 'primereact/components/datatable/datatable.css';
import {Color, Size} from '@vivli/shared/theme';
import {
    ButtonComponent,
    GoBackLinkComponent,
    LoadIndicatorComponent,
    TextAreaFormFieldComponent,
} from '@vivli/shared/components';
import {IOrganization} from '@vivli/features/organizations/infrastructure/interface';
import {IUserDetails} from '@vivli/features/users/infrastructure/interface';
import {useUserInfoContext, useUsersService} from '@vivli/features/users/infrastructure/context';
import {ConfirmButtonComponent} from '@vivli/features/users/components';
import {useToastService} from '@vivli/shared/infrastructure/context';
import {map} from 'rxjs/operators';
import {useCleanupHook} from '@vivli/shared/infrastructure/hook';
import {useActiveUser} from '@vivli/core/infrastructure/context';
import {OrgMembershipsGridComponent} from './org-memberships-grid.component';
import {AssociatedDataRequestsGridComponent} from './associated-data-requests-grid.component';
import {useFormContext} from "react-hook-form";


const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "flex-start",
    paddingBottom: 50,
    height: "100%",
    padding: 15,
    color: Color.BLACK,
    minWidth: 300,

};

const headerStyle: CSSProperties = {
    padding: '15px 20px',
    borderBottom: '1px solid white',
    fontSize: Size.FontSize.Larger,
    alignContent: 'center',
};

const bodyContainerStyle: CSSProperties = {
    padding: 20,
    height: "100%",
    fontSize: Size.FontSize.Medium,
};

const bodyStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgb(233, 234, 239)',
    paddingLeft: Size.PADDING,
    paddingRight: Size.PADDING,
    paddingTop: Size.PADDING,
    paddingBottom: Size.PADDING,
    color: 'black',
    overflowY: 'scroll',
    height: "90%",
};

const sectionStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: "25px",
    paddingLeft: "25px",
    justifyContent: 'space-between',
};

const section2Style: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
};


const sectionContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
};

const rowStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
};

const columnStyle: CSSProperties = {
    fontSize: 20,
    padding: 10,
};

const fieldStyle: CSSProperties = {
    fontSize: 20,
    marginRight: 20,
    paddingTop: 6,
};

const buttonStyle: CSSProperties = {
    backgroundColor: Color.VIVLI_BLUE,
    marginBottom: 'auto',
    height: 34,
    fontSize: 13,
    marginRight: -20,
};

const sectionHeaderStyle: CSSProperties = {
    fontSize: 22,
    display: "flex",
    marginTop: "25px",
    marginBottom: "15px",
    justifyContent: "center",
    alignItems: "center",
    padding: '20px 10px 0 10px',
};

const buttonFieldStyle: CSSProperties = {
    minWidth: '150px',
};

const goBackButtonStyle: CSSProperties = {
    display: "flex",
    paddingLeft: "20px",
    justifyContent: "flex-start",
    marginBottom: "5px"
}

const notesFieldStyle: CSSProperties = {
    paddingRight: "25px",
    paddingLeft: "25px",
    display: "flex",
    gap: "10px",
    flexDirection: "column",
}
const saveNoteButton: CSSProperties = {
    marginTop: "10px",
    display: "flex",
    justifyContent: "flex-end"
}

interface UserDetailsComponentProps {
    currentUser: IUserDetails;
    organizations: IOrganization[];
}

export const UserDetailsComponent = ({
                                         currentUser,
                                         organizations,
                                     }: UserDetailsComponentProps) => {
    useCleanupHook();

    const activeUser = useActiveUser();
    const [user, setUser] = useState<IUserDetails>(currentUser);
    const [newPassword, setNewPassword] = useState<string>(null);
    const isVivliAdmin = activeUser.isVivliAdmin;
    const newPasswordRef = useRef<HTMLInputElement>();
    const usersService = useUsersService();
    const toastService = useToastService();
    const {handleUpdateUserInfo, isSavingNotes, userDataRequests} = useUserInfoContext();
    const formApi = useFormContext()

    const copyPasswordToClipboard = () => {
        navigator.clipboard.writeText(newPassword).then(() => {
            toastService.success('Password copied to clipboard.');
        });
    };

    const updateUserStatus = () => {
        const newStatus = !user.disabled;
        return usersService.setUserStatus(user.id, newStatus).pipe(
            map(() => {
                const updatedUser = {...user, disabled: newStatus};

                setUser(updatedUser);

                const message = newStatus
                    ? `Successfully disabled ${user.displayName}`
                    : `Successfully enabled ${user.displayName}`;
                toastService.success(message);
            })
        );
    };

    const updateVivliAdminStatus = () => {
        const newStatus = !user.isVivliAdmin;
        return usersService.setVivliAdminPermission(user.id, newStatus).pipe(
            map(() => {
                const updatedUser = {
                    ...user,
                    isVivliAdmin: newStatus,
                    userRole: {
                        ...user.userRole,
                        isVivliAdmin: newStatus,
                    },
                };

                setUser(updatedUser);

                const message = newStatus
                    ? `Successfully made ${user.displayName} a Vivli Admin`
                    : `Successfully revoked Vivli Admin permission from ${user.displayName}`;
                toastService.success(message);
            })
        );
    };

    const resetUserPassword = () =>
        usersService.resetUserPassword(user.id).pipe(
            map((password) => {
                toastService.success(`Successfully reset password for ${user.displayName}`);
                return password;
            })
        );


    return (
        <div style={containerStyle}>
            {user && organizations && (
                <div style={{height: "100%"}}>
                    <div style={goBackButtonStyle}>
                        <GoBackLinkComponent destination={'/admin/users/'}/>
                    </div>
                    <div data-test-id='user-detail-header' style={headerStyle}>
                        User Details - {user.displayName} {user.roleName}
                    </div>
                    <div style={bodyContainerStyle}>
                        <div style={bodyStyle} className="scrolly">
                            <div>
                                <div style={sectionStyle}>
                                    <div>
                                        <div data-test-id='user-detail-dispaly-name'
                                             style={{...rowStyle, marginRight: 20}}>
                                            <div style={columnStyle}>Display Name:</div>
                                            <div style={columnStyle}>{user.displayName}</div>
                                        </div>
                                        <div data-test-id='user-detail-email' style={rowStyle}>
                                            <div style={columnStyle}>Email:</div>
                                            <div style={columnStyle}>{user.email}</div>
                                        </div>
                                        <div data-test-id='user-detail-last-login' style={rowStyle}>
                                            <div style={columnStyle}>Days Since Last Login:</div>
                                            <div style={columnStyle}>{user.daysSinceLastLogin}</div>
                                        </div>
                                    </div>
                                    <div style={sectionContainerStyle}>
                                        <div data-test-id='user-detail-update-user-status' style={section2Style}>
                                            <div style={fieldStyle}>Update User Status:</div>
                                            <div style={buttonFieldStyle}>
                                                <ConfirmButtonComponent
                                                    confirmMessage={
                                                        user.disabled
                                                            ? 'Are you sure you want to enable this user?'
                                                            : 'Are you sure you want to disable this user?'
                                                    }
                                                    apiCall={updateUserStatus}
                                                >
                                                    {user.disabled ? 'Enable' : 'Disable'}
                                                </ConfirmButtonComponent>
                                            </div>
                                        </div>

                                        <div data-test-id='user-detail-password'
                                             style={{...section2Style, paddingTop: 10}}>
                                            <div style={fieldStyle}>
                                                {newPassword ? 'New Password:' : 'Reset Password:'}
                                            </div>
                                            <div style={buttonFieldStyle}>
                                                <input
                                                    type="text"
                                                    style={{display: 'none'}}
                                                    ref={newPasswordRef}
                                                />
                                                {newPassword ? (
                                                    <ButtonComponent
                                                        style={buttonStyle}
                                                        onClick={copyPasswordToClipboard}
                                                    >
                                                        Copy Password to Clipboard
                                                    </ButtonComponent>
                                                ) : (
                                                    <ConfirmButtonComponent
                                                        confirmMessage="Reset Password?"
                                                        apiCall={resetUserPassword}
                                                        callback={(confirmed, result) =>
                                                            confirmed && setNewPassword(result)
                                                        }
                                                    >
                                                        Reset
                                                    </ConfirmButtonComponent>
                                                )}
                                            </div>
                                        </div>

                                        {activeUser?.isOperationsAdmin && (
                                            <div data-test-id='user-detail-admin-permission'
                                                 style={{...section2Style, paddingTop: 10}}>
                                                <div style={fieldStyle}>
                                                    {user.isVivliAdmin
                                                        ? 'Is Vivli Admin:'
                                                        : 'Is Not Vivli Admin:'}
                                                </div>
                                                <div style={buttonFieldStyle}>
                                                    <ConfirmButtonComponent
                                                        title={
                                                            user.isVivliAdmin
                                                                ? 'Revoke'
                                                                : 'Make Admin'
                                                        }
                                                        confirmMessage={
                                                            user.isVivliAdmin
                                                                ? 'Are you sure you want to revoke the Vivli Admin permission from this user?'
                                                                : 'Are you sure you want to make this user a Vivli Admin?'
                                                        }
                                                        apiCall={updateVivliAdminStatus}
                                                    >
                                                        {user.isVivliAdmin
                                                            ? 'Revoke'
                                                            : 'Make Admin'}
                                                    </ConfirmButtonComponent>
                                                </div>
                                            </div>
                                        )}

                                        {(isVivliAdmin) &&
                                            <div style={saveNoteButton}>
                                                <ButtonComponent
                                                    style={{
                                                        minWidth: '150px',
                                                        backgroundColor: Color.ERROR_RED,
                                                        fontSize: 13
                                                    }}
                                                    onClick={() => handleUpdateUserInfo()}
                                                    isLoading={isSavingNotes}
                                                    disabled={!formApi.formState.isDirty}
                                                >
                                                    Save Notes
                                                </ButtonComponent>
                                            </div>

                                        }
                                    </div>
                                </div>


                                {(isVivliAdmin) &&
                                    <div style={notesFieldStyle}>
                                        <div style={{fontSize: 22}}>Notes</div>
                                        <TextAreaFormFieldComponent
                                            name={'notes'}
                                            style={{height: '55px', width: "100%"}}
                                        />
                                    </div>
                                }


                                <div>
                                    <div style={sectionHeaderStyle}>Org Memberships</div>
                                    {user.userRole.orgMemberships?.length > 0 ? (
                                        <div data-test-id='user-detail-org-membership-grid'>
                                            <OrgMembershipsGridComponent
                                                user={user}
                                                setUser={setUser}
                                                organizations={organizations}
                                            />
                                        </div>
                                    ) : (
                                        <div style={{
                                            padding: 40,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            marginBottom: 10
                                        }}>
                                            No Organization Memberships Found
                                        </div>
                                    )}
                                </div>
                                <div style={{textAlign: "center"}}>
                                    <div style={sectionHeaderStyle}>Associated Data Requests</div>
                                    {userDataRequests ? (
                                        <div data-test-id='user-detail-associated-dataRequest-grid'>
                                            <AssociatedDataRequestsGridComponent
                                                userDataRequests={userDataRequests}
                                                isVivliAdmin={isVivliAdmin}
                                            />
                                        </div>
                                    ) : (
                                        <LoadIndicatorComponent
                                            style={{margin: 'auto', marginTop: '10px'}}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
