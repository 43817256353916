import React from "react";
import {DropdownFormFieldComponent, FieldHeaderComponent, TextFormFieldComponent} from "@vivli/shared/components";
import {IDropdownMenuItem} from "@vivli/shared/infrastructure/interface";
import {Size} from "@vivli/shared/theme";

const detailsSectionStyle = {
    display: 'flex',
    justifyContent: 'space-around',
}

const bottomMarginStyle = {
    marginBottom: Size.PADDING
}

const bottomAndLeftMarginStyle = {
    marginBottom: Size.PADDING, marginLeft: Size.PADDING
}

const listOptions = [
    { value: 'Academic', label: 'Academic'},
    {value: 'Industry', label: 'Industry'},
    {value: 'Government Organization', label: 'Government Organization'},
    {value: 'Patient Organization', label: 'Patient Organization'},
    {value: 'Non-Profit', label: 'Non-Profit'},
    {value: 'Other', label: 'Other'},
    {value: 'Unassigned', label: 'Unassigned'},
]

const typeItems = listOptions?.map(option => ({
    title: option.label,
    value: option.value
}) as IDropdownMenuItem);

const organizationCodeHint = 'Organization code must be at least a 3 digit alphanumeric code.'
const organizationUrlHint = 'Data Contributor ' +
    'organizations working with Vivli Partner Platforms should include the exact URL of the platform ' +
    'that will provide studies or study IPD. Please copy the URL from the browser input window.'

export const OrgDetailsSectionComponent = () => {

    return (
        <div>
            <FieldHeaderComponent title={'ORGANIZATION DETAILS'}/>
            <div style={detailsSectionStyle}>
                <DropdownFormFieldComponent items={typeItems} name={'type'} label={'Type'} style={bottomMarginStyle}/>
                <TextFormFieldComponent name={'emailDomain'} label={'Domain'} style={bottomAndLeftMarginStyle}/>
                <TextFormFieldComponent name={'code'} label={'Code'} style={bottomAndLeftMarginStyle} hint={organizationCodeHint}/>
            </div>
            <div>
                <TextFormFieldComponent name={'organizationRegistryUrl'} label={'Organization/Platform URL for Requesting Studies'} hint={organizationUrlHint}/>
            </div>
        </div>
    )
}
