import {useEffect, useState} from "react";
import {filter, finalize} from "rxjs/operators";
import {useUsersService} from "@vivli/features/users/infrastructure/context";
import {IUserDetails} from "@vivli/features/users/infrastructure/interface";

export function useUsers() {
    const [users, setUsers] = useState<IUserDetails[]>();
    const [isLoadingUsers, setIsLoadingUsers] = useState(false);

    const usersService = useUsersService();

    useEffect(() => {
        setIsLoadingUsers(true);
        const usersSub = usersService.getUsers()
            .pipe(
                filter(users => users !== null),
                finalize(() => setIsLoadingUsers(false))
            )
            .subscribe(setUsers);

        return () => {
            usersSub.unsubscribe();
        }
    }, [])

    return {
        users,
        isLoadingUsers,
    };
}
