import React, {CSSProperties} from 'react';
import moment from "moment";
import {Size} from "@vivli/shared/theme";
import {DownloadOnlyDataPackageFeature} from "@vivli/shared/features/data-package/feature";
import {IDataRequest} from "@vivli/features/data-requests/infrastructure/interface";
import {DataRequestStatusEnum} from "@vivli/features/data-requests/infrastructure/enum";
import {VivliAdminCommentsComponent} from "./vivli-admin-comments.component";
import {StudyRowDetailComponent} from "./study-row-detail.component";
import {useVivliConfig} from "@vivli/core/infrastructure/hook";
import {TeamMemberComponent} from "@vivli/shared/components";
import {useAnalysisPurposeTextMap} from "@vivli/features/data-requests/infrastructure/hook";
import {OutcomesOptions} from "@vivli/features/data-requests/infrastructure/constants";

const containerStyle: CSSProperties = {
    flex: 'auto',
    marginBottom: 20,
    paddingLeft: Size.PADDING
}

const innerContainerStyle: CSSProperties = {
    paddingLeft: Size.PADDING,
    borderLeft: '1px solid grey'
}

const outerContainerStyle: CSSProperties = {
    marginRight: Size.INSET_SIZE,
    marginLeft: Size.INSET_SIZE
}

interface DataRequestViewComponentProps {
    dataRequest: IDataRequest;
    isPrintModal?: boolean;
    hideReviewers: boolean;
    onReady?: () => void;
}

export const DataRequestViewComponent = (
    {
        dataRequest,
        isPrintModal,
        hideReviewers,
        onReady
    }: DataRequestViewComponentProps
) => {
    const vivliConfig = useVivliConfig();

    if (!dataRequest || !vivliConfig) { return null; }

    const partner = dataRequest.partner && vivliConfig?.partners?.find(p => p.name === dataRequest.partner);
    const leadResearcherDetail = dataRequest.researchTeam.researchTeamDetails.find(rtd => rtd.isLeadResearcher);
    const statisticianDetail = dataRequest.researchTeam.researchTeamDetails.find(rtd => rtd.isStatistician);

    const analysisPurposeMap = useAnalysisPurposeTextMap();
    const purposeTranslations = dataRequest.researchProposal?.purposeOfAnalysis?.map(a => analysisPurposeMap(a));

    const selectedOutcomes = OutcomesOptions.filter(o => dataRequest.researchProposal?.outcomes?.includes(o.value));
    const outcomeTranslations = selectedOutcomes?.map(o => o.label);

    const language = navigator.language;
    const showDoi : boolean =  dataRequest.status ===  DataRequestStatusEnum.Fulfilled || dataRequest.status === DataRequestStatusEnum.PartiallyFulfilled;

    return (
        <div style={outerContainerStyle}>
            <h1 style={{ fontSize: 23 }}>Research Data Request: {dataRequest.researchProposal.title}</h1>
            <h1 style={{ fontSize: 20 }}>{dataRequest.idString}</h1>

            {showDoi &&
                <h1 style={{ fontSize: 23 }}>
                    Data Request DOI: {dataRequest.secondaryAnalysisDoi ? dataRequest.secondaryAnalysisDoi  : 'N/A'}
                </h1>
            }

            <VivliAdminCommentsComponent history={dataRequest.dataRequestHistoryEntries} isPrintModal={isPrintModal}/>

            {dataRequest.partner && (
                <h1 style={{ fontWeight: 'bold', fontSize: 20 }}>
                    <span style={{ marginRight: '10px'}}>This request originated in the partner platform:</span>
                    <span style={{textTransform: 'uppercase'}}>{partner ? partner.displayname : dataRequest.partner}</span>
                </h1>
            )}

            <h1>Research Team</h1>
            <div style={containerStyle}>
                {dataRequest.researchTeam.leadIsStatistician ? <h2>Lead Investigator and Statistician</h2> : <h2>Lead Investigator</h2>}
                <TeamMemberComponent hideReviewers={hideReviewers} member={leadResearcherDetail} />
                {!dataRequest.researchTeam.leadIsStatistician &&
                    <div>
                        <h2>Statistician Researcher</h2>
                        <TeamMemberComponent hideReviewers={hideReviewers} member={statisticianDetail} />
                    </div>
                }
                {dataRequest.researchTeam.researchTeamDetails.length > 0 &&
                    <h2>Additional Researchers</h2>
                }
                {dataRequest.researchTeam.researchTeamDetails.filter(
                    rtd => !rtd.isLeadResearcher && !rtd.isStatistician,
                ).map((a, i) => {
                    return <TeamMemberComponent hideReviewers={hideReviewers} memberName={`Team Member ${i + 1}`} key={`${i}-tm`} member={a} />;
                })
                }

            </div>

            <h1>Research Proposal</h1>
            <div style={containerStyle}>
                <h2>General</h2>
                <div style={innerContainerStyle}>
                    <h3>Title of Proposed Research</h3>
                    {dataRequest.researchProposal.title}
                    {/* <h3>Requires Ethics Committee or IRB Approval</h3>
                        {dataRequest.researchProposal.irbApprovalRequired ? 'YES' : 'NO'}
                        <div>{dataRequest.researchProposal.furtherDetailForNoIRBApprovalResearch}</div> */}
                    <h3>Lay Summary of the Research Request</h3>
                    <pre>{dataRequest.researchProposal.laySummary}</pre>
                    <h3>Aims/Objectives and Hypotheses</h3>
                    <pre>{dataRequest.researchProposal.specificAimsObjectives}</pre>
                    <h3>Purpose of Analysis</h3>
                    {purposeTranslations.length > 0 && purposeTranslations.map((p, index) => (
                        <pre key={`${index}-p`}>
                            {p === 'Other' ?
                                `Other: ${dataRequest.researchProposal.purposeOfAnalysisOtherDescription && dataRequest.researchProposal.purposeOfAnalysisOtherDescription}` :
                                p}
                            </pre>
                    ))
                    }
                    <h3>Outcome(s)</h3>
                    {outcomeTranslations.length > 0 && outcomeTranslations.map((o, index) => (
                        <pre key={`${index}-o`}>
                            {o === 'Other' ?
                                `Other: ${dataRequest.researchProposal.outcomesOtherDescription}` :
                                o}
                            </pre>
                    ))
                    }
                </div>
                <h2>Study Design</h2>
                <div style={innerContainerStyle}>
                    <h3>Brief Description</h3>
                    <pre>{dataRequest.researchProposal.researchProposalStudyDesign.briefDescription}</pre>
                    <h3>Outcome Elements Categorization/Definitions</h3>
                    <pre>{dataRequest.researchProposal.researchProposalStudyDesign.elementsforOutcomes}</pre>
                    <h3>Main Predictor / Independent Variable</h3>
                    <pre>{dataRequest.researchProposal.researchProposalStudyDesign.mainPredictorIndependentVariable}</pre>
                    <h3>Other Variables of Interest</h3>
                    <pre>{dataRequest.researchProposal.researchProposalStudyDesign.otherVariablesOfInterest}</pre>
                </div>
                <h2>Project Timeline</h2>
                <div style={innerContainerStyle}>
                    <h3>Target Analysis Start Date</h3>
                    {language === 'en-US' ? moment(dataRequest.researchProposal.projectTimeline.projectStartDate).format('M/D/YY') : moment(dataRequest.researchProposal.projectTimeline.projectStartDate).format('D/M/YY')}
                    <h3>Estimated Analysis Completion Date</h3>
                    {language === 'en-US' ? moment(dataRequest.researchProposal.projectTimeline.analysisCompletionDate).format('M/D/YY') : moment(dataRequest.researchProposal.projectTimeline.analysisCompletionDate).format('D/M/YY')}
                </div>
                <h2>Dissemination and Publication Plan</h2>
                <div style={innerContainerStyle}>
                    <h3>Plan</h3>
                    <pre>{dataRequest.researchProposal.disseminationAndPublicationPlan}</pre>
                    <h3>Citations</h3>
                    <pre>{dataRequest.researchProposal.citations}</pre>
                </div>
            </div>

            <h1>Statistical Analysis Plan</h1>
            <div style={containerStyle}>
                <h2>General Plan</h2>
                <div style={innerContainerStyle}>
                    <pre>{dataRequest.statisticalAnalysisPlan.description}</pre>
                </div>
                <h2>Countries where analysis will be conducted</h2>
                <div style={innerContainerStyle}>
                    <pre>{dataRequest.statisticalAnalysisPlan.analysisLocation}</pre>
                </div>
            </div>

            <h1>Funding</h1>
            <div style={containerStyle}>
                <h2>General</h2>
                <div style={innerContainerStyle}>
                    <h3>Government Funding</h3>
                    {dataRequest.funding.researchGrantsFromGovernments ? 'YES' : 'NO'}
                    <div>{dataRequest.funding.researchGrantsFromGovernmentsDetail}</div>
                    <h3>Employment Contracts</h3>
                    {dataRequest.funding.fundingFromEmployers ? 'YES' : 'NO'}
                    <div>{dataRequest.funding.fundingFromEmployersDetail}</div>
                    <h3>Additional Contracts or Consultancies</h3>
                    {dataRequest.funding.additionalContractsAndConsultancies ? 'YES' : 'NO'}
                    <div>{dataRequest.funding.additionalContractsAndConsultanciesDetail}</div>
                    <h3>Commercial Funding</h3>
                    {dataRequest.funding.fundingFromCommercialOrganizations ? 'YES' : 'NO'}
                    <div>{dataRequest.funding.fundingFromCommercialOrganizationsDetail}</div>

                </div>
            </div>

            <h1>Other Information</h1>
            <div style={containerStyle}>
                <div style={innerContainerStyle}>
                    {dataRequest.otherInformation}
                </div>
            </div>

            <h1>Requested Studies</h1>
            <div style={containerStyle}>

                {dataRequest.requestedStudies.map((study, index) => (
                    <div key={`studyRow-${index}`} style={{ paddingLeft: Size.PADDING, borderLeft: '1px solid grey', marginBottom: 15 }}>
                        <StudyRowDetailComponent
                            key={`${index}-srd`}
                            requestedStudy={study}
                            dataRequestDoi={dataRequest.dataRequestDoi}
                            ipdStatusDescription={''}
                        />
                        {dataRequest.status === DataRequestStatusEnum.Fulfilled && <div style={{marginTop: 8, marginBottom: 8, fontSize: 15 }}>DOIs: Study DOI: {study.vivliStudyDoi}  DataPackage DOI: {study.requestedStudyChildDoi}</div>}
                    </div>
                ))}
            </div>

            <h1>Attached Files</h1>
            <div style={containerStyle}>
                <DownloadOnlyDataPackageFeature
                    dataPackageId={dataRequest.metaDataDataPackageId}
                    onReady={onReady}
                />
            </div>
        </div>
    )
}
