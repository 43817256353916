﻿import React, { CSSProperties, useEffect, useState } from 'react';
import { Size } from '@vivli/shared/theme';
import { TextFieldComponent } from './input/text-field.component';
import { ButtonComponent } from './buttons/button.component';
import { FieldHeaderComponent } from './field-header.component';
import { MultiEntryBubbleComponent } from './multi-entry-bubble.component';
import { MetricsConstants } from '@vivli/features/dashboard/infrastructure/constants';

const flexOneStyle: CSSProperties = {
    flex: 1,
};
const containerDiv: CSSProperties = {
    flex: 2,
};
const inputWrapperStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
};
const buttonWrapperStyle: CSSProperties = {
    ...flexOneStyle,
    marginLeft: `${MetricsConstants.Sizing.SPACING}px`,
};
const bubbleContainerStyle: CSSProperties = {
    marginTop: Size.PADDING,
};
const bubbleStyle: CSSProperties = {
    marginLeft: Size.PADDING,
    marginRight: Size.PADDING,
    marginBottom: Size.PADDING,
};

interface MultiEntryTextFieldComponentProps {
    title?: string;
    label?: string;
    useLabelFromParent?: boolean;
    onChange?(values: string[]);
    showClearAllButton?: boolean;
    clearButtonText?: string;
    textDivStyle?: CSSProperties;
    value?: string[];
    defaultValue?: string[];
    ref?: HTMLInputElement;
    isReadOnly?: boolean;
}

export const MultiEntryTextFieldComponent = ({
    title,
    label,
    useLabelFromParent,
    onChange,
    showClearAllButton,
    clearButtonText,
    textDivStyle,
    value,
    isReadOnly,
}: MultiEntryTextFieldComponentProps) => {
    const [text, setText] = useState<string>('');
    const [textArray, setTextArray] = useState(value || []);
    const labelTextToUse = useLabelFromParent ? '' : label;

    const handleKeyDown = (e) => {
        if (!isReadOnly && e.key === Size.Key.Enter) {
            const newValToSend = [...textArray, e.target.value];
            setTextArray(newValToSend);
            setText('');
            onChange(newValToSend);
        }
    };
    const handleOnRemove = (removedValue) => {
        if (!isReadOnly) {
            const newValToSend = textArray.filter((t) => t !== removedValue);
            setTextArray(newValToSend);
            onChange(newValToSend);
        }
    };
    const handleClearButton = () => {
        setText('');
        setTextArray([]);
    };

    useEffect(() => {
        if (value && value.length > 0) {
            setTextArray(value);
        }
    }, [value]);

    return (
        <div style={containerDiv}>
            {title && <FieldHeaderComponent title={title} />}
            <div style={inputWrapperStyle}>
                <TextFieldComponent
                    label={labelTextToUse}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e)}
                    divStyle={{ ...flexOneStyle, ...textDivStyle }}
                />
                {showClearAllButton && (
                    <div style={buttonWrapperStyle}>
                        <ButtonComponent disabled={!textArray.length} onClick={handleClearButton}>
                            {clearButtonText ?? 'Clear'}
                        </ButtonComponent>
                    </div>
                )}
            </div>
            {textArray && (
                <div style={bubbleContainerStyle}>
                    {textArray?.map((value, index) => (
                        <MultiEntryBubbleComponent
                            key={value + index}
                            style={bubbleStyle}
                            value={value}
                            onRemove={handleOnRemove}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};
