import { IStudy } from '@vivli/features/studies/infrastructure/interface';

export const useStudyPrimaryRegistryUrlHook = (study: IStudy) => {
    if (study?.extractedSponsorClinicalRegistryUrl) {
        return study.extractedSponsorClinicalRegistryUrl;
    }

    if (study?.nctId) {
        return 'https://clinicaltrials.gov/show/' + study.nctId;
    }

    return 'N/A';
};
