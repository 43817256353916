﻿import {GridComponent} from "@vivli/shared/components";
import React, {CSSProperties, useState} from "react";
import {IUserDetails} from "@vivli/features/users/infrastructure/interface";
import {IOrganization} from "@vivli/features/organizations/infrastructure/interface";
import {ColDef} from "ag-grid-community";
import {MemberRowRendererComponent} from "@vivli/features/users/components";
import {IOrganizationRoles} from "@vivli/shared/infrastructure/interface";
import {useToastService} from "@vivli/shared/infrastructure/context";

/*
* TODO: When we upgrade ag-grid to or beyond v28, we can re-evaluate if this is necessary
*  This is only here as a way around not having access to the wrapHeaderText and headerHeight properties for ag-grid yet.
*  This style sets white-space to normal instead of no-wrap which is the default ag-grid setting.
*/
import './grid-styles.css';

const firstOrganizationRowStyle: CSSProperties = {
    fontSize: '20px'
}

interface OrgMembershipsGridComponentProps {
    user: IUserDetails,
    setUser: React.Dispatch<React.SetStateAction<IUserDetails>>,
    organizations?: IOrganization[],
}
export const OrgMembershipsGridComponent = (
    {
        user,
        setUser,
        organizations,
    }: OrgMembershipsGridComponentProps
) => {
    const [inProgressRows, setInProgressRows] = useState<string[]>([]);
    const toastService = useToastService();

    const updateInProgressRows = (isLoading: boolean, orgId: string) => {
        const updatedRows = inProgressRows.filter(id => id !== orgId);

        if (isLoading) {
            updatedRows.push(orgId);
        }

        setInProgressRows(updatedRows);
    }
    const hasInProgressRow = (orgId: string) => {
        return inProgressRows.some(id => id === orgId);
    }
    const updateUserRoles = (organizationRoles: IOrganizationRoles) => {
        const orgMemberships = [...user.userRole.orgMemberships.filter(x => x.orgId !== organizationRoles.orgId), organizationRoles]
        const updatedUser = {...user, userRole: { ...user.userRole, orgMemberships}};

        setUser(updatedUser)

        toastService.success(`Successfully updated organization member rights for ${user.displayName}`);
    }

    const columnDefs: ColDef[] = [
        {
            field: 'orgName',
            headerName: 'Name',
            sortable: true,
            filter: false,
            initialSort: 'asc',
            tooltipField: 'orgName',
            wrapText: true,
            cellStyle: firstOrganizationRowStyle,
            flex: 2
        }, {
            field: 'isOrgAdmin',
            headerName: 'Org Admin',
            sortable: false,
            filter: false,
            cellRenderer: 'MemberRow',
            cellRendererParams: {
                user: user,
                getOrganization: (orgId) => organizations.find(o => o.id === orgId),
                onLoading: updateInProgressRows,
                getDisabled: (orgId) => hasInProgressRow(orgId),
                onMemberUpdated: updateUserRoles
            },
            flex: 1
        }, {
            field: 'isAnnotator',
            headerName: 'Curator',
            sortable: false,
            filter: false,
            cellRenderer: 'MemberRow',
            cellRendererParams: {
                user: user,
                getOrganization: (orgId) => organizations.find(o => o.id === orgId),
                onLoading: updateInProgressRows,
                getDisabled: (orgId) => hasInProgressRow(orgId),
                onMemberUpdated: updateUserRoles
            },
            flex: 1
        }, {
            field: 'isQaReviewer',
            headerName: 'QA Reviewer',
            sortable: false,
            filter: false,
            cellRenderer: 'MemberRow',
            cellRendererParams: {
                user: user,
                getOrganization: (orgId) => organizations.find(o => o.id === orgId),
                onLoading: updateInProgressRows,
                getDisabled: (orgId) => hasInProgressRow(orgId),
                onMemberUpdated: updateUserRoles
            },
            flex: 1
        }, {
            field: 'isDataProvider',
            headerName: 'Data Contributor',
            sortable: false,
            filter: false,
            cellRenderer: 'MemberRow',
            cellRendererParams: {
                user: user,
                getOrganization: (orgId) => organizations.find(o => o.id === orgId),
                onLoading: updateInProgressRows,
                getDisabled: (orgId) => hasInProgressRow(orgId),
                onMemberUpdated: updateUserRoles
            },
            flex: 1
        }, {
            field: 'isIRPApprover',
            headerName: 'IRP / Reviewer',
            sortable: false,
            filter: false,
            cellRenderer: 'MemberRow',
            cellRendererParams: {
                user: user,
                getOrganization: (orgId) => organizations.find(o => o.id === orgId),
                onLoading: updateInProgressRows,
                getDisabled: (orgId) => hasInProgressRow(orgId),
                onMemberUpdated: updateUserRoles
            },
            flex: 1
        }, {
            field: 'isHead',
            headerName: 'Head Curator',
            sortable: false,
            filter: false,
            cellRenderer: 'MemberRow',
            cellRendererParams: {
                user: user,
                getOrganization: (orgId) => organizations.find(o => o.id === orgId),
                onLoading: updateInProgressRows,
                getDisabled: (orgId) => hasInProgressRow(orgId),
                onMemberUpdated: updateUserRoles
            },
            flex: 1
        }, {
            field: 'isHead',
            headerName: 'Head QA Reviewer',
            sortable: false,
            filter: false,
            cellRenderer: 'MemberRow',
            cellRendererParams: {
                user: user,
                getOrganization: (orgId) => organizations.find(o => o.id === orgId),
                onLoading: updateInProgressRows,
                getDisabled: (orgId) => hasInProgressRow(orgId),
                onMemberUpdated: updateUserRoles
            },
            flex: 1
        }
    ]

    const customComponents = [
        {
            name: 'MemberRow',
            component: MemberRowRendererComponent
        }
    ]

    const organizationsWithNames = () => {
        return user.userRole.orgMemberships.map(roles => {
            const orgName = organizations.find(o => o.id === roles.orgId)?.name;

            return {
                ...roles,
                orgName
            }
        }).sort((a, b) => a.orgName.localeCompare(b.orgName))
    }

    return (
        <GridComponent
            rowData={organizationsWithNames()}
            columns={columnDefs}
            emptyMessage='No Organization Memberships Found'
            autoHeight={true}
            hideFloatingFilter={true}
            rowHeight={50}
            customComponents={customComponents}
        />
    )
}
