﻿import {
    IOrganization,
    IOrganizationInvitation,
    IRorResponseItem
} from "@vivli/features/organizations/infrastructure/interface";
import {createContext, useContext} from "react";

interface IOrganizationDetailsContext {
    organization?: IOrganization;
    invitations?: IOrganizationInvitation[];
    invitationsCount?: number;
    handleSave?: (updatedOrg) => void;
    isSaving?: boolean;
    inviteOrgMember?: (invite) => void;
    deleteInvite?: (invite) => void;
    getRorMatches?: (searchTerm) => IRorResponseItem[];
    currentMembers?: IOrganization[];
    setCurrentMembers?: IOrganization[];

}

export const OrganizationDetailsContext = createContext<IOrganizationDetailsContext>(null);

export const useOrganizationDetailsContext = () => useContext(OrganizationDetailsContext);


