import React, {ReactNode, useState} from "react";
import {AdminConsoleContext, useAdminConsoleService} from "@vivli/features/admin-console/infrastructure/context";
import {first} from "rxjs/operators";
import {useModalService} from "@vivli/shared/infrastructure/context";
import {IProvisionAdminOptions} from "@vivli/features/admin-console/infrastructure/interface";

interface AdminConsoleContextWrapperProps {
    children: ReactNode;
}

export const AdminConsoleContextWrapper = ({children}: AdminConsoleContextWrapperProps) => {

    const modalService = useModalService();
    const adminConsoleService = useAdminConsoleService();
    const [isLoading, SetIsLoading] = useState<boolean>();
    const handleAction = (action: string,  id: string, data: string, isAsync:boolean = false) => {
        modalService.confirm(`Please comment and confirm selected action - ${action}.`, {
            title: "Action Confirmation.",
            showTextAreaPrompt: true,
            requireComment: true,
            confirmText: 'Ok',
            cancelText: 'Cancel',
            style: {minWidth: '45em', textAlign: 'left'},
            messageStyle: {textAlign: 'left'},
            onConfirm: ({comment}) => {
                SetIsLoading(true);
                adminConsoleService.performAction(action, id, data, comment)
                    .pipe(first())
                    .subscribe(() => {
                        SetIsLoading(false);
                          modalService.message(isAsync?`Action ${action} has started. It may take some time to complete.`:`Action ${action} succeded.`,
                                { title: "Action Success",
                                    style: {minWidth: '45em', textAlign: 'left',
                                        backgroundColor:'rgb(5, 61, 88)',
                                        borderRadius: '15px', color: "white"},
                                    showContinueButton: true,
                                    messageStyle: {textAlign: 'left', color: "white"},

                                });
                        },
                        handleError
                    );
            }
        });

    }
    const provisionVm = (id: string,  options: IProvisionAdminOptions , callback: ()=>void) => {
        modalService.confirm(`Please comment and confirm VM Provisioning.`, {
            title: "Action Confirmation.",
            showTextAreaPrompt: true,
            requireComment: true,
            confirmText: 'Ok',
            cancelText: 'Cancel',
            style: {minWidth: '45em', textAlign: 'left'},
            messageStyle: {textAlign: 'left'},
            onConfirm: ({comment}) => {
                SetIsLoading(true);
                options.comment = comment;
                adminConsoleService.provisionVm(id, options)
                    .pipe(first())
                    .subscribe(() => {
                            SetIsLoading(false);
                            callback();//start polling
                            modalService.message(`VM Provisioning has started. It will take some time to complete.`,
                                {
                                    title: "Action Success",
                                    style: {
                                        minWidth: '45em', textAlign: 'left',
                                        backgroundColor: 'rgb(5, 61, 88)',
                                        borderRadius: '15px', color: "white"
                                    },
                                    showContinueButton: true,
                                    messageStyle: {textAlign: 'left', color: "white"},

                                });
                        },
                        handleError
                    );
            }
        });
    }

    const handleError = (err: string) => {
        SetIsLoading(false);
        modalService.error(`An error occurred with your operation. Please try again or contact Vivli support: ${err}`);
    }
    const provider = {
        handleAction,
        isLoading,
        provisionVm
    }
    return <AdminConsoleContext.Provider value={provider}>
        {children}
    </AdminConsoleContext.Provider>

}
