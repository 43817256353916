import React, {CSSProperties, useEffect, useState} from 'react';
import {CheckboxFormFieldComponent, FieldHeaderComponent, InfoPopoverComponent} from "@vivli/shared/components";
import {useWatch} from "react-hook-form";
import {IResearchTeamMemberDetail} from "@vivli/features/data-requests/infrastructure/interface";

const headerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexGrow: 1
} as React.CSSProperties;

const subtitleStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    height: '50px',
    color: 'rgb(5, 61, 88)'
}

const infoPopupStyle: CSSProperties = {
    minHeight: '30px',
    alignItems: 'center'
}

const checkboxStyle: CSSProperties = {
    flexDirection: 'row-reverse'
}

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1
}

const helpContent = <div>
    <div>For each member of the research team, please fill out the information below.</div>
    <br/>
    <div>Note that the Lead Researcher (PI) and Statistician cannot be edited once the Data Request is submitted to
        Vivli.
    </div>
    <br/>
    <div>Note that the email address is used in the member information of the data request. Once you have activated a user for
        the Data Request you cannot change their email. If you need to do this, cancel, then revoke access
        and then change the email.
    </div>
</div>

interface TeamMemberControlsFormComponentProps {
    baseFieldName?: string;
    isLoading?: boolean;
    readonly?: boolean;
    subTitle: string;
    setLeadIsStatistician?: (leadIsStatistician: boolean) => void;
    teamMember: IResearchTeamMemberDetail;
}

export const TeamMemberControlsFormComponent = (
    {
        baseFieldName,
        isLoading,
        subTitle,
        readonly,
        setLeadIsStatistician,
        teamMember
    }: TeamMemberControlsFormComponentProps
) => {
    const [initialized, setInitialized] = useState(false);
    const isStatistician = useWatch({ name: `${baseFieldName}isStatistician` });

    useEffect(() => {
        if (!initialized) {
            setInitialized(true);
            return;
        }

        if (!teamMember.isLeadResearcher || !setLeadIsStatistician) { return; }
        setLeadIsStatistician(isStatistician);
    }, [isStatistician])

    return (
        <div style={containerStyle}>
            <div style={headerStyle}>
                <FieldHeaderComponent title={subTitle} style={subtitleStyle}/>

                {!teamMember.hasBeenInvitedToVMAccess && !teamMember.hasAccessToVirtualMachineResource &&
                <CheckboxFormFieldComponent
                    name={`${baseFieldName}inviteToVirtualMachine`}
                    label={'Activate user for accessing data request'}
                    readonly={isLoading}
                    truncateLabel={false}
                    style={checkboxStyle}
                />}

                {teamMember.isLeadResearcher && setLeadIsStatistician && <CheckboxFormFieldComponent
                    name={`${baseFieldName}isStatistician`}
                    label={'Lead Researcher is also Statistician Researcher'}
                    readonly={readonly || isLoading}
                    truncateLabel={false}
                    style={checkboxStyle}
                    dataId={'LeadResearcher_checkbox'}
                />}
            </div>

            <InfoPopoverComponent content={helpContent} style={infoPopupStyle} />
        </div>
    )
}
