﻿import React from "react";
import {
    internalMenuButtonContainerStyle,
    internalMenuButtonOuterContainerStyle,
    internalMenuTextStyle
} from "./internal-link-menu-styles";

export const EnquiryLinkComponent = () => {

    const handleClick = () => {
        const enquiryLink = 'https://vivli.org/members/enquiries-about-studies-not-listed-on-the-vivli-platform/';
        window.open(enquiryLink, '_blank');
    }

    return (
        <div style={internalMenuButtonOuterContainerStyle}>
            <div
                style={internalMenuButtonContainerStyle}
                onClick={handleClick}
                className='header_enquiry'
                data-test-id={'header_enquiry'}
            >
                <div style={internalMenuTextStyle}>
                    ENQUIRY
                </div>
            </div>
        </div>
    )
}
