import React, {useEffect, useState} from 'react';
import {AdminHeaderButtonComponent} from "@vivli/shared/components";
import {useDataRequestContext, useDataRequestsService} from "@vivli/features/data-requests/infrastructure/context";
import {useModalService} from "@vivli/shared/infrastructure/context";

export const SetTrackingButtonComponent = () => {
    const [buttonTitle, setButtonTitle] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>();
    const dataRequestsService = useDataRequestsService();
    const {dataRequest, updateDataRequest, setIsUpdatingStatus, isUpdatingStatus} = useDataRequestContext();
    const modalService = useModalService();

    const handleTrackClick = () => {
        setIsLoading(true);
        setIsUpdatingStatus(true);
        dataRequestsService
            .setTracking(dataRequest.id, !dataRequest.doNotTrack)
            .subscribe(updatedDataRequest => {
                updateDataRequest(updatedDataRequest);
                setIsLoading(false);
                setIsUpdatingStatus(false);
            }, modalService.error);
    }

    const updateButtonTitle = () => {
        setButtonTitle(dataRequest?.doNotTrack ? 'Track' : 'Do not track');
    }

    useEffect(() => {
        updateButtonTitle();
    }, [dataRequest])

    return <AdminHeaderButtonComponent
        title={buttonTitle}
        style={{marginRight: 10}}
        onClick={handleTrackClick}
        isDisabled={isUpdatingStatus}
        isLoading={isLoading}
    />
}
