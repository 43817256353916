﻿import React, {CSSProperties} from "react";
import tinycolor from "tinycolor2";
import {Styles, Size, Color} from "@vivli/shared/theme";
import {useNavigate} from "react-router-dom";

const innerDivStyle: CSSProperties = {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    ...Styles.Transition.HOVER_OPACITY_TRANSITION,
}

interface NavPanelComponentProps {
    panelItem: { name?: string; title?: string; icon?: { active: any; inactive: any }; url?: string; defaultTab?: string };
    panelItemsLength: number;
    index: number;
    panelGap: number;
    actualAdminLeftPanelWidth: number;
}
export const NavPanelComponent = (
    {
        panelItem,
        panelItemsLength,
        index,
        panelGap,
        actualAdminLeftPanelWidth
    }: NavPanelComponentProps
) =>{
    const navigate = useNavigate();
    const isActive = window.location.href.includes("/" + panelItem.url);

    //Calculated Styles
    const panelWidth = isActive ? actualAdminLeftPanelWidth : actualAdminLeftPanelWidth - panelGap;
    const marginBottom = index === panelItemsLength - 2 && isActive ? 0 : panelGap;
    const backgroundColor = isActive ? Color.LIGHT_GRAY : Color.WHITE;
    const flex = index === panelItemsLength - 1 ? '1 1 auto' : undefined;
    const spanStyle: CSSProperties = {
        color: isActive ? Color.TEXT_DARK_BLUE : tinycolor(Color.TEXT_DARK_BLUE).clone().lighten(20).toRgbString(),
        fontWeight: isActive ? Size.FontWeight.Bold : Size.FontWeight.Regular
    }
    const panelStyle: CSSProperties = {
        ...Styles.NO_SELECT,
        minHeight: Size.ADMIN_LEFT_PANEL_ITEM_HEIGHT,
        width: panelWidth,
        marginBottom: marginBottom,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: Size.PADDING,
        fontSize: Size.FontSize.Large,
        backgroundColor: backgroundColor,
        flex: flex,
        cursor: panelItem.url ? 'pointer' : 'default',
        pointerEvents: 'inherit',
    }
    const panelClass = 'adminPanel_' + (panelItem.title ? panelItem.title.replace(/[^\w]/gi, '') : 'empty');

    const handleClick = () => {
        if (panelItem.url) {
            navigate(`/admin/${panelItem.url}/${panelItem.defaultTab}`);
        }
    }

    return (
        <div
            style={panelStyle}
            className={panelClass}
            data-test-id={'navigation_panel'}
            onClick={handleClick}
        >
            <div style={innerDivStyle}>
                {panelItem.icon && (
                    <img
                        src={isActive ? panelItem.icon.active : panelItem.icon.inactive}
                        style={{ marginRight: Size.PADDING }}
                    />
                )}
                <span style={spanStyle}>
                    {panelItem.title}
                </span>
            </div>
        </div>
    )
}
