﻿import {UserInfoContext, useUsersService} from "@vivli/features/users/infrastructure/context";
import {IContextWrapper} from "@vivli/shared/infrastructure/interface";
import {useToastService} from "@vivli/shared/infrastructure/context";
import {useEffect, useState} from "react";
import {IDataRequest} from "@vivli/features/data-requests/infrastructure/interface";
import {useActiveUser} from "@vivli/core/infrastructure/context";
import {useFormContext} from "react-hook-form";

export const UserInfoContextWrapper = ({children}: IContextWrapper) => {

    const activeUser = useActiveUser();
    const isVivliAdmin = activeUser.isVivliAdmin;
    const usersService = useUsersService()
    const formApi = useFormContext()
    const toastService = useToastService();
    const [isSavingNotes, setIsSavingNotes] = useState<boolean>(false)
    const [userDataRequests, setUserDataRequests] = useState<IDataRequest[]>();
    const user = formApi.getValues();

    const handleUpdateUserInfo = (notes = formApi.getValues().notes) => {
        setIsSavingNotes(true)
        usersService.updateUserNote(user.id, notes).subscribe(
            () => {
                setIsSavingNotes(false);
                toastService.success("Notes saved.");
            },
            () => toastService.error("Notes not saved.")
        )
    }

    const handleAssociatedDataRequests = (dataRequests) => {

        // filter out dr to only show that are actually associated to user and the user is active.
        const associatedDataRequests = dataRequests.filter(dr =>
            dr.researchTeam.researchTeamDetails.some(tm =>
                tm.userId === user.id && tm.hasAccessToVirtualMachineResource)
        ).map(dr => {                                        //mapped to add userRoles
            const presentUser = dr.researchTeam.researchTeamDetails
                .find(tm => tm.userId === user.id);
            const presentUserRoles = [];
            if (presentUser) {
                if (presentUser.isAdmin) presentUserRoles.push('Admin');
                if (presentUser.isLeadResearcher) presentUserRoles.push('Lead Researcher');
                if (presentUser.isStatistician) presentUserRoles.push('Statistician');
                if (!presentUser.isAdmin && !presentUser.isLeadResearcher && !presentUser.isStatistician)
                    presentUserRoles.push('Additional Researcher');
            }

            return {
                ...dr,
                userRoles: presentUserRoles,
                ...(isVivliAdmin && {envType: dr.antimicrobialDetail === null ? 'Clinical Trials' : 'AMR'})
            };
        });
        setUserDataRequests(associatedDataRequests);
    }


    useEffect(() => {

        const userDataRequestsSub = usersService
            .getUserDataRequests(user.id)
            .subscribe(handleAssociatedDataRequests);

        return () => {
            userDataRequestsSub.unsubscribe();
        };
    }, []);


    const provider = {
        handleUpdateUserInfo,
        isSavingNotes,
        userDataRequests,

    }

    return <UserInfoContext.Provider value={provider}>
        {children}
    </UserInfoContext.Provider>

}
