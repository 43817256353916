﻿import React from "react";
import {Trans} from "react-i18next";
import {Size} from "@vivli/shared/theme";

export const WelcomePopupComponent = () => {

    const webAppUri = window.location.href;
    const submissionsUrl = `${webAppUri}study-submission`;

    const infoTextStyle = {
        fontSize: Size.FontSize.Large,
        lineHeight: '1.4',
        fontFamily: 'Verdana, sans-serif',
    };

    {/*
        Below code translates between amr-translations and default-translations. In translations constant
        file, use <x> in relation to the item's index in the components array below. If complex elements such
        as ones with attributes or nesting are required, they should be added to the components array.
        For example of how it should look in translations constant file: <0>support@vivli.org</0> .
     */
    }
    return (
        <Trans style={infoTextStyle}
               components={[
                   <a style={infoTextStyle} href="mailto:support@vivli.org"/>,
                   <a style={infoTextStyle}
                      href="https://vivli.org/members/enquiries-about-studies-not-listed-on-the-vivli-platform/"/>,
                   <a style={infoTextStyle} href="mailto:amr@vivli.org"/>,
                   <a style={infoTextStyle} href={submissionsUrl}/>,
                   <a style={infoTextStyle} href="https://vivli.org/resources/sharedata/"/>,
                   <a style={infoTextStyle} href="https://vivli.org/resources/requestdata/"/>,
                   <a style={infoTextStyle} href="https://vivli.org/resources/resources/"/>,

               ]}>
            popUpWelcomeMessage
        </Trans>
    )
}
