import React, { CSSProperties, useEffect, useState } from 'react';
import {
    ExportCsvButtonComponent,
    TabComponent,
    TabsComponent,
} from '@vivli/shared/components';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import {
    StudyStatusEnum,
    StudyTabEnum,
} from '@vivli/features/studies/infrastructure/enum';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { GridApi } from 'ag-grid-community';
import 'react-slidedown/lib/slidedown.css';
import './studies.style.scss';
import { StudiesGridComponent } from '@vivli/features/studies/components';
import { useStudies } from '@vivli/features/studies/infrastructure/hook';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@vivli/features/users/infrastructure/hook';
import { Size } from '@vivli/shared/theme';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';
import {useNavigate} from "react-router-dom";

const containerStyle: CSSProperties = {
    height: '100%',
    padding: `0 ${Size.INSET_SIZE}px`,
};

enum tabEnum {
    draft,
    inprogress,
    posted,
    cancelled,
}

export const StudiesFeature = () => {
    const [inProgressStudies, setInProgressStudies] = useState<IStudy[]>();
    const [cancelledStudies, setCancelledStudies] = useState<IStudy[]>();
    const [draftStudies, setDraftStudies] = useState<IStudy[]>();
    const [postedStudies, setPostedStudies] = useState<IStudy[]>();
    const [activeTab, setActiveTab] = useState<number>(tabEnum.draft);
    const [cancelledGridApi, setCancelledGridApi] = useState<GridApi>(null);
    const [postedGridApi, setPostedGridApi] = useState<GridApi>(null);
    const [draftGridApi, setDraftGridApi] = useState<GridApi>(null);
    const [inProgressGridApi, setInProgressGridApi] = useState<GridApi>(null);
    const assignedAppType = useAssignedAppType();
    const user = useActiveUser();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const userPermissions = useUserPermissions();

    const { studies, isLoadingStudies } = useStudies();

    //Confirmed with product owner that the rules for AMR and nonAMR are different
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const nonAdminAmrUser = isAmr && !user.isVivliAdmin;
    const showTitle =
        nonAdminAmrUser ||
        (!isAmr && !user.isVivliAdmin && !userPermissions.isOrgAdmin)
            ? null
            : `Add ${t('study')}`;
    const optionAction = [
        {
            title: `Single ${t('study')}`,
            onClick: () => navigate('/admin/studies/submitStudy'),
        },
        {
            title: `Multiple ${t('studies')}`,
            onClick: () => navigate('/admin/bulkUploadStudies/true'),
        },
        {
            title: `Update ${t('studies')}`,
            onClick: () => navigate('/admin/bulkUpdateStudies'),
        },
    ];
    const showOptions = isAmr && !user.isVivliAdmin ? null : optionAction;

    const setInitialTab = () => {
        const currentPath = window.location.pathname;
        const routePath = currentPath
            .replace('/admin/studies/', '')
            ?.toLowerCase();

        if (!routePath) {
            return;
        }

        switch (routePath) {
            case 'inprogress':
                setActiveTab(tabEnum.inprogress);
                break;
            case 'posted':
                setActiveTab(tabEnum.posted);
                break;
            case 'cancelled':
                setActiveTab(tabEnum.cancelled);
                break;
        }
    };

    const getSubheader = () => {
        return user.orgMemberships.filter((om) => om.isOrgAdmin).length > 1
            ? 'Submitted by my organizations'
            : 'Submitted by my organization';
    };

    const exportToCsv = (tab: tabEnum) => {
        const dateStr = new Date().toLocaleDateString().replace('/', '_');
        switch (tab) {
            case tabEnum.cancelled:
                cancelledGridApi.exportDataAsCsv({
                    fileName: `Cancelled_Studies_${dateStr}`,
                });
                break;
            case tabEnum.posted:
                postedGridApi.exportDataAsCsv({
                    fileName: `Posted_Studies_${dateStr}`,
                });
                break;
            case tabEnum.inprogress:
                inProgressGridApi.exportDataAsCsv({
                    fileName: `InProgress_Studies_${dateStr}`,
                });
                break;
            case tabEnum.draft:
                draftGridApi.exportDataAsCsv({
                    fileName: `Draft_Studies_${dateStr}`,
                });
                break;
        }
    };

    useEffect(() => {
        setInitialTab();
    }, []);

    useEffect(() => {
        if (!studies) {
            return;
        }

        const postedStudies = studies.filter(
            (s) => s.status === StudyStatusEnum.Posted
        );
        const draftStudies = studies.filter(
            (s) => s.status === StudyStatusEnum.Draft
        );
        const cancelledStudies = studies.filter(
            (s) => s.status === StudyStatusEnum.Cancelled
        );
        const inProgressStudies = studies.filter(
            (s) =>
                s.status !== StudyStatusEnum.Cancelled &&
                s.status !== StudyStatusEnum.Draft &&
                s.status !== StudyStatusEnum.Posted
        );

        setPostedStudies(postedStudies);
        setDraftStudies(draftStudies);
        setCancelledStudies(cancelledStudies);
        setInProgressStudies(inProgressStudies);
    }, [studies]);

    return (
        <div style={containerStyle}>
            <TabsComponent
                defaultTab={activeTab}
                commandImage={AssetsConstant.PLUS_SYMBOL}
                commandTitle={showTitle}
                commandOnClick={() => {
                    if (!user.isVivliAdmin) {
                        navigate('/admin/studies/submitStudy');
                    }
                }}
                commandOptions={showOptions}
                title={t('studies')}
                subTitle={getSubheader()}
            >
                <TabComponent
                    title={StudyTabEnum.Draft}
                    count={draftStudies?.length}
                    onTabClick={() => {
                        navigate('/admin/studies/draft');
                    }}
                    rightHeaderContent={
                        <ExportCsvButtonComponent
                            onClick={() => exportToCsv(tabEnum.draft)}
                        />
                    }
                >
                    <StudiesGridComponent
                        type="Drafted"
                        studies={draftStudies}
                        onGridReady={setDraftGridApi}
                        dataRefreshing={isLoadingStudies}
                    />
                </TabComponent>
                <TabComponent
                    title={StudyTabEnum.InProgress}
                    count={inProgressStudies?.length}
                    hint={'Studies awaiting curation'}
                    onTabClick={() => {
                        navigate('/admin/studies/inprogress');
                    }}
                    rightHeaderContent={
                        <ExportCsvButtonComponent
                            onClick={() => exportToCsv(tabEnum.inprogress)}
                        />
                    }
                >
                    <StudiesGridComponent
                        type="Submitted"
                        studies={inProgressStudies}
                        onGridReady={setInProgressGridApi}
                        dataRefreshing={isLoadingStudies}
                    />
                </TabComponent>
                <TabComponent
                    title={StudyTabEnum.Posted}
                    count={postedStudies?.length}
                    onTabClick={() => {
                        navigate('/admin/studies/posted');
                    }}
                    rightHeaderContent={
                        <ExportCsvButtonComponent
                            onClick={() => exportToCsv(tabEnum.posted)}
                        />
                    }
                >
                    <StudiesGridComponent
                        type="Posted"
                        studies={postedStudies}
                        onGridReady={setPostedGridApi}
                        dataRefreshing={isLoadingStudies}
                    />
                </TabComponent>
                <TabComponent
                    title={StudyTabEnum.Cancelled}
                    count={cancelledStudies?.length}
                    onTabClick={() => {
                        navigate('/admin/studies/cancelled');
                    }}
                    rightHeaderContent={
                        <ExportCsvButtonComponent
                            onClick={() => exportToCsv(tabEnum.cancelled)}
                        />
                    }
                >
                    <StudiesGridComponent
                        type="Drafted"
                        studies={cancelledStudies}
                        onGridReady={setCancelledGridApi}
                        dataRefreshing={isLoadingStudies}
                    />
                </TabComponent>
            </TabsComponent>
        </div>
    );
};
