import React, { CSSProperties, ReactNode } from 'react';
import { Color, Size } from '@vivli/shared/theme';
import { ButtonComponent } from '../buttons/button.component';
import { LoadIndicatorComponent } from '../load-indicator/load-indicator.component';
import { WhiteBackgroundLogoComponent } from '../white-background-logo.component';
import { LoadIndicatorSizeEnum } from '@vivli/shared/infrastructure/enum';

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: Color.WHITE,
    minWidth: 300,
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 15px, rgba(0, 0, 0, 0.2) 0px 1px 2px',
};

const textStyle: CSSProperties = {
    padding: '15px',
    fontSize: Size.FontSize.Large,
    textAlign: 'center',
};

const buttonContainerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
};

const buttonStyle: CSSProperties = {
    width: '200px',
};

interface MessageModalComponentProps {
    message: ReactNode;
    children?: ReactNode;
    onClick?: () => void;
    onOverlayClick?: () => void;
    showContinueButton?: boolean;
    showLogo?: boolean;
    showLoadingSpinner?: boolean;
    style?: CSSProperties;
    messageStyle?: CSSProperties;
    title?: string;
}

const titleStyle: CSSProperties = {
    borderBottom: '2px solid white',
    padding: '15px',
};

const loadingDivStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '10px',
};

const logoStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '30px',
};

export const MessageModalComponent = ({
    message,
    children,
    onClick,
    showContinueButton,
    showLogo,
    showLoadingSpinner,
    style,
    title,
    messageStyle,
    onOverlayClick,
}: MessageModalComponentProps) => {
    const handleClick = () => {
        onClick && onClick();
    };

    return (
        <div style={{ ...containerStyle, ...style }} onClick={onOverlayClick}>
            {showLogo && (
                <div style={logoStyle}>
                    <WhiteBackgroundLogoComponent />
                </div>
            )}
            {title && <div style={titleStyle}>{title}</div>}
            {showLoadingSpinner && (
                <div style={loadingDivStyle}>
                    <LoadIndicatorComponent size={LoadIndicatorSizeEnum.Large} />
                </div>
            )}
            <div style={{ ...textStyle, ...messageStyle }}>{message}</div>
            {children}
            {showContinueButton && (
                <div style={buttonContainerStyle}>
                    <ButtonComponent onClick={handleClick} style={buttonStyle}>
                        Continue
                    </ButtonComponent>
                </div>
            )}
        </div>
    );
};
