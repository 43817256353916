﻿import React, { CSSProperties, useEffect, useState } from 'react';
import { IOrganizationMember } from '@vivli/features/organizations/infrastructure/interface';
import { ICellRendererParams } from 'ag-grid-community';
import { ButtonComponent } from '@vivli/shared/components';
import { Color } from '@vivli/shared/theme';
import { IOrganizationRoles } from '@vivli/shared/infrastructure/interface';
import { useOrganizationsService } from '@vivli/features/organizations/infrastructure/context';
import { useToastService } from '@vivli/shared/infrastructure/context';

const deactivateButtonStyle: CSSProperties = {
    margin: 'auto',
    minWidth: 87,
    height: 34,
    fontSize: 13,
    paddingLeft: 10,
    paddingRight: 10,
    borderBottom: `1px solid ${Color.ERROR_RED}`,
    borderLeft: `1px solid ${Color.ERROR_RED}`,
    borderRight: `1px solid ${Color.ERROR_RED}`,
    borderTop: `1px solid ${Color.ERROR_RED}`,
    backgroundColor: Color.ERROR_RED,
    color: Color.WHITE,
};

const activateButtonStyle: CSSProperties = {
    margin: 'auto',
    height: 34,
    fontSize: 13,
    paddingLeft: 10,
    paddingRight: 10,
    minWidth: 87,
    borderBottom: `1px solid ${Color.VIVLI_LIGHT_BLUE}`,
    borderLeft: `1px solid ${Color.VIVLI_LIGHT_BLUE}`,
    borderRight: `1px solid ${Color.VIVLI_LIGHT_BLUE}`,
    borderTop: `1px solid ${Color.VIVLI_LIGHT_BLUE}`,
    backgroundColor: Color.VIVLI_LIGHT_BLUE,
    color: Color.WHITE,
};

interface OrgMemberRowRenderComponentProps extends ICellRendererParams {
    data: IOrganizationMember;
    orgId: string;
}
export const OrgMemberRowRenderComponent = ({ data, column, orgId }: OrgMemberRowRenderComponentProps) => {
    const userToUpdate = data.userRole;
    const fieldName = column.getColDef().field;
    const toastService = useToastService();

    const [isLoading, setIsLoading] = useState(false);
    const [currentOrgRoles, setCurrentOrgRoles] = useState<IOrganizationRoles>();
    const [currentRoleValue, setCurrentRoleValue] = useState<boolean>(false);
    const { setOrganizationMemberRights } = useOrganizationsService();
    const className = (currentRoleValue ? 'orgDetails_cancel_' : 'orgDetails_activate_') + fieldName.replace(/[^\w-]/gi, '');

    const handleRoleUpdate = () => {
        setIsLoading(true);

        const updatedMemberRights = { ...currentOrgRoles };
        updatedMemberRights[fieldName] = !currentRoleValue;

        const payload: IOrganizationRoles = {};
        payload[fieldName] = !currentRoleValue;
        setOrganizationMemberRights(orgId, userToUpdate.id, userToUpdate.email, payload).subscribe((result) => {
            if (result) {
                setCurrentOrgRoles(updatedMemberRights);
                setCurrentRoleValue(updatedMemberRights[fieldName]);
                toastService.success(`Successfully updated organization member rights for ${data.displayName}`);
            }
            setIsLoading(false);
        });
    };

    useEffect(() => {
        if (!orgId || !userToUpdate) {
            return;
        }

        const _currentOrgRoles = userToUpdate.orgMemberships.find((m) => m.orgId === orgId);
        if (_currentOrgRoles) {
            setCurrentOrgRoles(_currentOrgRoles);
            setCurrentRoleValue(_currentOrgRoles[fieldName]);
        }
    }, [orgId, userToUpdate]);

    return (
        <div style={{ marginTop: '0.6em' }}>
            <ButtonComponent
                isLoading={isLoading}
                style={currentRoleValue ? deactivateButtonStyle : activateButtonStyle}
                title=""
                disabled={isLoading}
                className={className}
                onClick={handleRoleUpdate}
            >
                {currentRoleValue ? 'Deactivate' : 'Activate'}
            </ButtonComponent>
        </div>
    );
};
