import { CSSProperties } from 'react';
import { useResearchEnvironmentContext } from '@vivli/features/data-requests/infrastructure/context';
import { ButtonComponent } from '@vivli/shared/components';
import { Size } from '@vivli/shared/theme';

const containerStyle: CSSProperties = { marginBottom: Size.PADDING * 2 };

const buttonStyle: CSSProperties = {
    minWidth: 250,
    height: 55,
};

export const VmProvisionedConnectComponent = () => {
    const { virtualMachine } = useResearchEnvironmentContext();

    const connectToVm = () => {
        window.open(`/virtual-machine/${virtualMachine.id}`);
    };

    return (
        <div style={containerStyle}>
            <ButtonComponent onClick={connectToVm} style={buttonStyle}>
                Connect to Environment
            </ButtonComponent>
        </div>
    );
};
