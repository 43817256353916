import React, {CSSProperties, ReactNode, useState} from 'react';
import Tippy from "@tippyjs/react";
import {QuestionMarkComponent} from "./question-mark.component";

const tippyContainerStyle: CSSProperties = {
    position: 'relative',
    minHeight: '20px',
    display: 'flex',
    marginLeft: '5px',
    cursor: 'pointer'
}

const contentStyle: CSSProperties = {
    minWidth: '300px'
}

interface InfoPopoverComponentProps {
    content: ReactNode;
    size?: number;
    style?: CSSProperties;
}

export const InfoPopoverComponent = (
    {
        content,
        size,
        style
    }: InfoPopoverComponentProps
) => {
    const [visible, setVisible] = useState(false);

    return (
        <div style={{...tippyContainerStyle, ...style}}>
            <Tippy
                content={<div style={contentStyle} className='scrolly'>{content}</div>}
                visible={visible}
                onClickOutside={() => setVisible(false)}
                className='tippy-info'
                interactive={true}
            >
                <QuestionMarkComponent size={size} onClick={() => setVisible(true)} />
            </Tippy>
        </div>
    )
}
